import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Grid, Layout, Row } from 'antd'

import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import WrapperLogo from './logo/Logo.component'
import { mainLayoutPM } from './mainLayout.pm'
import SideMenu from './menu/SideMenu/SideMenu.component'

const { Sider } = Layout
const { useBreakpoint } = Grid

interface MainLayoutProps {
  children?: React.ReactNode
}

const StyledSider = styled(Sider)`
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  height: 100%;
`

const MainLayout = ({ children }: MainLayoutProps) => {
  const screens = useBreakpoint()

  return (
    <Layout style={{ minHeight: '100vh', height: '100%' }}>
      <Row>
        {!screens.lg ? (
          <Col span={24} lg={0}>
            <StyledSider
              trigger={null}
              collapsible
              collapsed={mainLayoutPM.collapsed}
              onCollapse={mainLayoutPM.toggleCollapsed}
              breakpoint="lg"
              collapsedWidth="0"
            >
              <WrapperLogo />
              <SideMenu />
            </StyledSider>
          </Col>
        ) : (
          <Col span={0} lg={24}>
            <StyledSider
              trigger={
                <Button
                  type="text"
                  size="small"
                  icon={mainLayoutPM.collapsed ? <RightOutlined /> : <LeftOutlined />}
                  style={{
                    fontSize: '16px',
                  }}
                />
              }
              collapsible
              collapsed={mainLayoutPM.collapsed}
              onCollapse={mainLayoutPM.toggleCollapsed}
            >
              <WrapperLogo />
              <SideMenu />
            </StyledSider>
          </Col>
        )}
      </Row>
      {children}
    </Layout>
  )
}

export default observer(MainLayout)
