import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'lib/apollo/client'
import { useSignedIn } from 'stores/auth/hook'
import { GlobalStyle } from 'styles'
import { AppModalsAndDrawers } from './App.modals'
import ProductFruitsWrapper from './base/ProductFruits/ProductFruitsWrapper'
import { CenterLayout } from './layouts/center'
import { AppRoutes } from './routes'
import { Loading } from './routes/async'

export const MainApp = () => {
  const apolloClient = useApollo()
  const { signedIn } = useSignedIn()

  if (!signedIn) {
    return <CenterLayout><Loading /></CenterLayout>
  }

  return (
    <ApolloProvider client={apolloClient}>
      <GlobalStyle />
      <AppRoutes />
      <AppModalsAndDrawers />
      <ProductFruitsWrapper />
    </ApolloProvider>
  )
}
