import styled from 'styled-components'
import styles from 'styles'
import Layout from './styles'

const StyledLayout = styled(Layout)`
  width: 100vw;
  height: 100vh;
  position: relative;

  // .c-layout__content {
  //   background-color: #f9f9f9;
  // }

  // .bg {
  //   background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%27100%25%27%20height%3D%27100%25%27%20viewBox%3D%270%200%201600%20800%27%3E%3Cg%20opacity%3D%270.4%27%3E%3Cpath%20fill%3D%27%23ca4300%27%20d%3D%27M1600+0H0V800H1600z%27%2F%3E%3Cpath%20fill%3D%27%23d96900%27%20d%3D%27M1600+0H0v720c49-17+100-28+151-33c112-13+226-3+335+19c127+26+242+60+358+94h756V0z%27%2F%3E%3Cpath%20fill%3D%27%23e78b00%27%20d%3D%27M478+581c206+56+399+137+604+180c174+37+350+29+519-10V0H0v575c52-18+107-28+161-31c107-7+215+10+317+37z%27%2F%3E%3Cpath%20fill%3D%27%23f3ab00%27%20d%3D%27M0+0V429c56-18+114-27+171-28c103-1+203+23+299+55c193+65+375+149+571+195c187+44+376+34+558-13V0H0z%27%2F%3E%3Cpath%20fill%3D%27%23ffcc00%27%20d%3D%27M182+259c98+6+192+35+281+72c179+75+351+162+539+211c199+52+403+41+597-15V0H0v283c59-19+121-27+182-24z%27%2F%3E%3Cpath%20fill%3D%27%23d7c203%27%20d%3D%27M1600+0H0v136c62-21+128-28+192-19c94+12+181+48+263+90c166+85+328+175+508+227c211+61+431+49+637-17V0z%27%2F%3E%3Cpath%20fill%3D%27%23afb705%27%20d%3D%27M455+86c146+91+297+183+469+239c209+67+431+61+638-5c13-4+25-8+38-13V0H288c56+21+109+51+160+82z%27%2F%3E%3Cpath%20fill%3D%27%2385ab08%27%20d%3D%27M1600+0H498c118+86+244+165+387+216c192+69+400+75+595+21c41-11+81-25+120-42V0z%27%2F%3E%3Cpath%20fill%3D%27%23589e0a%27%20d%3D%27M1398+155c47-11+94-25+139-44c22-9+43-19+64-30V0H643c63+42+130+78+202+107c175+71+369+89+553+48z%27%2F%3E%3Cpath%20fill%3D%27%230a910c%27%20d%3D%27M1315+72c75-13+149-37+217-72H809c158+71+336+101+506+72z%27%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  //   background-attachment: fixed;
  //   background-size: cover;
  //   width: 100vw;  /* This ensures the container takes 100% width */
  //   height: 100vh;
  //   position: absolute;
  // }

  .cl-signIn-root {
    .cl-footer {
      justify-content: space-between;
    }

    .cl-signIn-start {
      .cl-footer {
        justify-content: center;
      }
    }
  }

  .cl-card {
    border: 1px solid rgba(19, 19, 22, 0.06);
    padding: 2.375rem 2rem 3rem;
    position: relative;

    .cl-footerAction__signIn {
      display: none;
    }

    .cl-headerTitle {
      font-family: Lato;
      font-weight: 600;
      text-align: center;
      font-size: 16px;
    }

    .cl-headerSubtitle {
      font-family: Lato;
      text-align: center;
      font-size: 14px;
    }
  }
`

const Patent = styled.div`
  color: ${styles.colors.gray6};
  margin-top: 15px;
  font-size: 12px;
`

/**
 * Simple centered layout for positioning content in
 * the center of the page, as per the Login page
 * @param {*} props
 */
export const ClerkLayout = (props) => (
  <StyledLayout>
    <div className="bg" />
    <Layout.Content className="c-layout__content flex">{props.children}</Layout.Content>
  </StyledLayout>
)
