import { SignInTheme } from "@clerk/types"
import styles from "styles"

export const elements: SignInTheme["elements"] = {
  card: {
    gap: "1rem"
  },
  footerPagesLink: {
    color: styles.colors.primary
  },
  // headerTitle: {
  //   display: "none"
  // },
  // headerSubtitle: {
  //   display: "none"
  // },
  logoBox: {
    height: 45,
    justifyContent: "center"
  }
}
