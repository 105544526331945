import type { Layout, Variables } from "@clerk/types";
import colors from "./colors";

export const variables: Variables = {
  colorPrimary: colors.primary,
  // colorDanger: colors.red,
  // colorSuccess: colors.green,
  // colorWarning: colors.yellow,
  // colorAlphaShade: colors.gray8,
  // colorTextOnPrimaryBackground: colors.black,
  // colorTextSecondary: colors.gray12,
  // colorBackground: colors.white,
  // colorInputText: colors.black,
  // colorInputBackground: colors.white,
  // fontFamily: "Lato",
  fontFamilyButtons: "Lato",
  fontFamily: "Lato"
}

export const layout: Layout = {
  helpPageUrl: "",
  logoPlacement: "inside",
  privacyPageUrl: 'https://pulse360.com/privacy',
  socialButtonsPlacement: 'bottom',
  socialButtonsVariant: 'iconButton',
  showOptionalFields: false,
  termsPageUrl: 'https://pulse360.com/tos',
}

