import { ClerkProvider } from '@clerk/clerk-react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { SIGNIN, SIGNUP } from 'constants/auth';
import { csp } from 'helpers/csp';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'routes/protected';
import { SignIn } from 'routes/signin';
import { SignUp } from 'routes/signup';
import { layout, variables } from 'styles/clerk';
import { MainApp } from './App';

const Index = () => (
    <ClerkProvider
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY as string}
      afterSignInUrl=''
      appearance={{
        variables,
        layout
      }}
    >
      <ConfigProvider locale={enUS}>
        {process.env.NODE_ENV === "production" && (
          <Helmet>
            <meta
              httpEquiv="Content-Security-Policy"
              content={csp()}
            ></meta>
          </Helmet>
        )}

        <Routes>
          {/* Public Routes */}
          <Route path={`${SIGNIN}/*`} element={<SignIn />} />
          <Route path={`${SIGNUP}`} element={<SignUp />} />

          {/* Private Routes */}
          <Route
            path={"/*"}
            element={
              <ProtectedRoute>
                <MainApp />
              </ProtectedRoute>
            }
            />
        </Routes>
      </ConfigProvider>
    </ClerkProvider>
)

export default Index
